import { FC } from 'react'
// import cn from 'classnames'
import { Divider } from 'antd'
import Barcode from 'react-barcode'
import dayjs from 'dayjs'

type Iprops = {
  heading: any
  billDate?: any
  isReturn: any
  itemDetails?: any
  count: any
  elRef?: any
  barcode: any
}
const BarcodePrint: FC<Iprops> = ({
  elRef,
  billDate,
  itemDetails,
  isReturn,
  heading,
  barcode,
  count,
}) => {
  return (
    <div
      ref={elRef}
      className="d-flex flex-column text-body-small fw-semibold px-8 pb-0"
    >
      <div className="d-flex justify-content-center">
        <span className="text-center">{heading}</span>
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 text-label-extra-small justify-content-between">
        <span className="text-label-extra-small text-truncate">
          B.No: <b>{barcode}</b>
        </span>
      </div>
      <div className="d-flex w-100 justify-content-between">
        <span className="text-label-extra-small w-100 text-truncate">
          <b>
            Date:{dayjs(billDate)?.local()?.format('DD-MM-YY hh:mm A') || '-'}
          </b>
        </span>
        {!!isReturn && (
          <span style={{ lineHeight: 0 }} className="text-label-extra-small">
            {'Returned'}
          </span>
        )}
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex justify-content-between">
        <span className="mt-2 w-100 text-label-extra-small text-truncate fw-semibold">
          Material:{' '}
          <b style={{ lineHeight: 1 }} className="text-label-medium">
            {itemDetails?.item || itemDetails?.item}
          </b>{' '}
        </span>
      </div>
      <div className="d-flex ">
        {itemDetails?.micron ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Micron:
              <b style={{ lineHeight: 1 }} className="text-label-medium ">
                {itemDetails?.micron || '-'}
              </b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
        {itemDetails?.material_size ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Size:
              <b style={{ lineHeight: 1 }} className="text-label-medium ">
                {itemDetails?.material_size || '-'}
              </b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="d-flex ">
        {itemDetails?.tar_quantity > 0 ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Tar:
              <b style={{ lineHeight: 1 }} className="text-label-medium ">
                {`${
                  itemDetails?.tar_quantity > 0
                    ? (itemDetails?.tar_quantity / count).toFixed(3)
                    : 0
                } ${itemDetails?.unit}` || '-'}
              </b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
        {itemDetails?.net_quantity ? (
          <>
            <span className="text-label-extra-small fw-semibold">
              Total:
              <b style={{ lineHeight: 1 }} className="text-label-medium">{`${
                (itemDetails?.net_quantity / count).toFixed(3) || '-'
              }${itemDetails?.unit}`}</b>
              &nbsp;&nbsp;
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <Divider className="my-0 border-black" dashed />
      <div className="d-flex w-100 justify-content-center">
        {barcode && (
          <Barcode displayValue={false} width={1} height={30} value={barcode} />
        )}
      </div>
    </div>
  )
}

export default BarcodePrint
