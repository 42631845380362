import {
  useReportForm,
  useReportFormType,
  useStockReport,
} from '@services/reports'
import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button, FormInput, Select, Table } from 'yolo-design/components'
import { Print } from 'yolo-design/icons'
import PrintFormat from './PrintFormat'

const StockReport = () => {
  const printRef = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const formCode = searchParams.get('form') || ''
  const micronSearch = searchParams.get('micronSearch') || ''
  const sizeSearch = searchParams.get('sizeSearch') || ''

  const formTypeCode = searchParams.get('form_type') || undefined
  const { data: reportFormType, isLoading } = useReportFormType(
    {
      onSuccess(data) {
        if (data?.length) {
          searchParams.set('form_type', data?.length ? data?.[0]?.value : '')
          setSearchParams(searchParams)
        }
      },
      staleTime: 0,
    },
    Number(formCode)
  )
  const reportForm = useReportForm({})
  const { data, isLoading: isLoadingTable } = useStockReport(
    {},
    {
      material_type: formTypeCode ? formTypeCode : '',
      form: formCode ? formCode : '',
      size: sizeSearch,
      micron: micronSearch,
    }
  )

  const handlePrint = useReactToPrint({
    documentTitle: `Stock Report - ${new Date().toLocaleString()}`,
    pageStyle: `@page { 
      size: 210mm 297mm;
      margin: 2mm;
       }`,
    content: () => printRef.current,
  })

  const columns = [
    {
      title: 'S No.',
      dataIndex: 'sno',
      width: '60px',
    },
    {
      title: 'Product Detail',
      dataIndex: 'name',
      render: (va: any, col: any) => (
        <span>
          {va} Size:{col?.material_size || '-'} Micron:{col?.micron || '-'}{' '}
        </span>
      ),
    },
    {
      title: 'Total No.',
      dataIndex: 'total_batch_count',
      width: '100px',
    },
    {
      title: 'Avail. No.',
      dataIndex: 'available_batch_count',
      width: '100px',
    },
    {
      title: 'Gross Wt.',
      dataIndex: 'total_stock',
      width: '120px',
    },
    {
      title: 'Iss Wt.',
      dataIndex: 'issued_stock',
      width: '120px',
    },
    {
      title: 'Stock.',
      dataIndex: 'stock',
      width: '120px',
    },
  ]

  return (
    <div className="d-flex flex-column w-100 align-items-center p-24">
      <span className=" text-heading-medium fw-semibold">Stock Statement</span>
      <div className="d-flex align-items-center w-100 mt-16 justify-content-between">
        <span>Total Records: {data?.length}</span>
        <div className="d-flex align-items-center  gap-12">
          <Select
            style={{
              width: '100px',
            }}
            size="small"
            allowClear={false}
            filterOption={false}
            placeholder="Select a Form"
            options={reportForm?.data?.map((el) => ({
              value: el.code,
              label: el.name,
            }))}
            onClear={() => {
              searchParams.delete('form')
              searchParams.delete('form_type')
              setSearchParams(searchParams)
            }}
            onChange={() => {
              searchParams.delete('form_type')
              setSearchParams(searchParams)
            }}
            onSelect={(e) => {
              searchParams.set('form', e)

              setSearchParams(searchParams)
            }}
            loading={reportForm.isLoading}
          />
          <Select
            style={{
              width: '100px',
            }}
            size="small"
            allowClear={true}
            filterOption={false}
            disabled={reportFormType?.length ? false : true}
            value={formTypeCode}
            placeholder="Select a Material Type"
            options={reportFormType}
            onClear={() => {
              searchParams.delete('form_type')
              setSearchParams(searchParams)
            }}
            onSelect={(e) => {
              searchParams.set('form_type', e)
              setSearchParams(searchParams)
            }}
          />
          <FormInput
            showErrorMessage={false}
            inputType="number"
            placeHolder="Size"
            antdInputProps={{
              style: {
                padding: '1px 7px',
                borderRadius: '4px',
                borderColor: 'black',
                width: '80px',
              },
              onChange: (e: any) => {
                searchParams.set('sizeSearch', e.target.value)
                setSearchParams(searchParams)
              },
            }}
          />
          <FormInput
            showErrorMessage={false}
            inputType="number"
            placeHolder="Micron"
            antdInputProps={{
              style: {
                padding: '1px 7px',
                borderRadius: '4px',
                borderColor: 'black',
                width: '80px',
              },
              onChange: (e: any) => {
                searchParams.set('micronSearch', e.target.value)
                setSearchParams(searchParams)
              },
            }}
          />
          <Button
            antdButtonProps={{ onClick: handlePrint, disabled: isLoading }}
            icon={Print}
            size="small"
          >
            Print
          </Button>
        </div>
      </div>
      <Table
        rowKey={'id'}
        loading={isLoadingTable}
        className="mt-8 w-100"
        columns={columns}
        dataSource={data?.map((el, i) => ({ ...el, sno: i + 1 }))}
      />
      <div className="w-100 d-none">
        <div ref={printRef} className="w-100">
          <PrintFormat data={data} />
        </div>
      </div>
    </div>
  )
}

export default StockReport
