import { queries } from '@constants/queryKeys'
import {
  IIssuedItemBatch,
  IMaterialReturn,
  IssuedMaterialsTableListItem,
  ViewMaterialIssueListItem,
  ViewMaterialIssueListItemBatch,
} from '@contracts/models/materials-return'
import {
  UseInfiniteQueryDataType,
  UseMutationType,
  UseQueryDataType,
} from '@contracts/query'
import useInfiniteQueryRef from '@hooks/useInfiniteQueryRef'
import * as API from './api'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useGetMaterialIssuesTable: UseInfiniteQueryDataType<
  IssuedMaterialsTableListItem,
  { page_size: number; status?: string; search: string }
> = (options, { page_size, status, search }) =>
  useInfiniteQueryRef({
    queryKey: queries.materials.getAllIssuedMaterialsData({
      page_size,
      status,
      search,
    }).queryKey,
    queryFn: ({ signal, pageParam }) =>
      API.getMaterialIssuesTableList({
        signal,
        page: pageParam,
        status,
        search,
        page_size,
      }),
    ...options,
  })

export const useGetMaterialIssuedById: UseQueryDataType<
  ViewMaterialIssueListItem,
  { id: string }
> = (options, { id }) =>
  useQuery({
    queryKey: queries.materials.getIssuedMaterialId(id).queryKey,
    queryFn: () => API.getIssuedMaterial(id),
    ...options,
  })

export const usePostReturnedMaterialById: UseMutationType<
  ViewMaterialIssueListItemBatch,
  {
    batch: number
    return_stocks: number
  }
> = (options) =>
  useMutation({
    mutationFn: API.postReturnMaterial,
    ...options,
  })

export const useUtilizeIssuedMaterial: UseMutationType<
  null,
  {
    batch: number
  }
> = (options) =>
  useMutation({
    mutationFn: API.putUtilizeMaterial,
    ...options,
  })

export const useMaterialReturnList: UseInfiniteQueryDataType<
  IMaterialReturn,
  { page_size: number; status?: string; search?: string }
> = (options, { page_size, status, search }) =>
  useInfiniteQueryRef<IMaterialReturn>({
    queryKey: queries.materials.getAllReturnMaterial({
      page_size,
      status,
      search,
    }).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getAllReturnMaterial(signal, {
        page: pageParam,
        status,
        search,
        page_size,
      })
    },
    ...options,
  })

export const useIssuedItemBatches: UseQueryDataType<
  IIssuedItemBatch[],
  { id: number; search: string }
> = (options, { id, search }) =>
  useQuery({
    queryKey: queries.materials.getAllIssuedItemBatch(id, search).queryKey,
    queryFn: ({ signal }) => API.getIssuedBatchList(signal, { id, search }),
    ...options,
  })
