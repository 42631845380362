import { ENV } from '@config/app.config'
import {
  IIssuedItemBatch,
  IMaterialReturn,
  IssuedMaterialsTableListItem,
  ViewMaterialIssueListItem,
} from '@contracts/models/materials-return'
import { PaginatedResponse } from '@contracts/models/shared'
import { GET, POST, PUT } from '@lib/AxiosClient'

export const getIssuedMaterial = (
  id: string
): Promise<ViewMaterialIssueListItem> =>
  GET({ url: `${ENV.API_BASE_URL}item/stock-issue/${id}/` })

export const getMaterialIssuesTableList = ({
  signal,
  page,
  status,
  search,
  page_size,
}: {
  signal: AbortSignal | undefined
  page: number
  status?: string
  search?: string
  page_size?: number
}): Promise<PaginatedResponse<IssuedMaterialsTableListItem>> => {
  return GET({
    url: `${ENV.API_BASE_URL}item/stock-issue/`,
    params: { page, page_size, status, search },
    signal,
  })
}

export const postReturnMaterial = (data: {
  batch: number
  return_stocks: number
}) => POST({ url: `${ENV.API_BASE_URL}item/stock-return/`, data })

export const putUtilizeMaterial = ({ batch }: { batch: number }) =>
  PUT({ url: `${ENV.API_BASE_URL}item/issued-product-status/${batch}/` })

export const getAllReturnMaterial = (
  signal: AbortSignal | undefined,
  params: {
    page: number
    status?: string
    search?: string
    page_size: number
  }
): Promise<PaginatedResponse<IMaterialReturn>> =>
  GET({
    url: `${ENV.API_BASE_URL}item/issue-return/`,
    params,
    signal,
  })

export const getIssuedBatchList = (
  signal: AbortSignal | undefined,
  d: {
    id: number
    search: string
  }
): Promise<IIssuedItemBatch[]> =>
  GET({
    url: `${ENV.API_BASE_URL}item/issued-batch/${d.id}/`,
    params: { search: d.search },
    signal,
  })
