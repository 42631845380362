import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  FormInput,
  FormWrapper,
  Tooltip,
  FormInputDate as YoloDate,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { Check, Print, ShortArrowDownLeft } from 'yolo-design/icons'
import { ViewMaterialIssueListItemBatch } from '@contracts/models/materials-return'
import { Badge, Form, message } from 'antd'
import cn from 'classnames'
import { UseMutationResult } from '@tanstack/react-query'
import dayjs from 'dayjs'

type propType = {
  data: ViewMaterialIssueListItemBatch
  saveReturnMaterial: UseMutationResult<
    ViewMaterialIssueListItemBatch,
    unknown,
    {
      batch: number
      return_stocks: number
    },
    unknown
  >
  updateUtilizeMaterial: UseMutationResult<
    null,
    unknown,
    {
      batch: number
    },
    unknown
  >
  showIssueName?: boolean
  handlePrint: any
}

const Item: FC<propType> = ({
  data,
  saveReturnMaterial,
  updateUtilizeMaterial,
  showIssueName = false,
  handlePrint,
}) => {
  const [showReturnForm, setShowReturnForm] = useState(false)
  const [showUtilizeBtn, setShowUtilizeBtn] = useState(false)
  const [form] = Form.useForm()

  const batchStatus =
    data.return_stocks > 0
      ? 'returned'
      : data.used_stocks === data.issue_stocks
      ? 'utilized'
      : 'issued'

  const handleFinish = (val: any) => {
    const postData = {
      batch: data.id,
      return_stocks: val.return_stocks,
      return_date: val?.return_date,
    }
    saveReturnMaterial.mutate(postData)
  }

  useEffect(() => {
    form.setFieldValue('return_date', dayjs())
  }, [])

  const Content = (
    <div className="mb-16 p-12 pt-8 pb-12 border border-grey-200 rounded-8">
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex flex-column">
          <div className="d-flex">
            <span className="text-uppercase text-body-large fw-semibold">
              {data?.barcode || '-'}
            </span>
            {batchStatus === 'returned' && (
              <Button
                size="extra_small"
                type="outlined"
                antdButtonProps={{
                  style: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    marginLeft: '8px',
                  },
                  onClick: () => {
                    handlePrint(data)
                  },
                }}
                borderColor={Color.background.dark}
              >
                <Print size="small" color={Color.background.dark} />
              </Button>
            )}
          </div>
          {showIssueName && (
            <span className="text-label-small fw-medium">
              Issue Number: {data?.issue_number || '-'}
            </span>
          )}
        </div>
        {batchStatus === 'issued' ? (
          <>
            {!showReturnForm && !showUtilizeBtn && (
              <div className="d-flex">
                <Tooltip title="Mark as Utilized">
                  <Button
                    antdButtonProps={{
                      style: { padding: '4px 8px' },
                      onClick: () => setShowUtilizeBtn(true),
                      disabled:
                        updateUtilizeMaterial.isLoading ||
                        saveReturnMaterial.isLoading,
                    }}
                    size="small"
                    type="outlined"
                    borderColor={Color.background.dark}
                  >
                    <Check color={Color.background.dark} weight="thin" />
                  </Button>
                </Tooltip>
                <Tooltip title="Make a Return">
                  <Button
                    antdButtonProps={{
                      style: { padding: '4px 8px' },
                      className: 'ms-8',
                      onClick: () => setShowReturnForm(true),
                      disabled:
                        updateUtilizeMaterial.isLoading ||
                        saveReturnMaterial.isLoading,
                    }}
                    size="small"
                    type="outlined"
                    borderColor={Color.background.dark}
                  >
                    <ShortArrowDownLeft
                      color={Color.background.dark}
                      weight="thin"
                    />
                  </Button>
                </Tooltip>
              </div>
            )}
          </>
        ) : (
          <></>
          // <span
          //   className={cn(
          //     'px-8 py-2 d-flex align-items-center h-max-content rounded-4 text-label-extra-small fw-bold',
          //     {
          //       'bg-washed-purple': batchStatus === 'utilized',
          //       'bg-washed-green': batchStatus === 'returned',
          //     }
          //   )}
          // >
          //   {batchStatus === 'returned' ? 'RETURNED' : 'UTILIZIED'}
          // </span>
        )}
      </div>
      <div className="d-flex text-body-medium mt-16">
        <div className="d-flex flex-column pe-24 ">
          <span className="text-grey-500 text-label-extra-small fw-bold">
            ISSUE QTY&nbsp;
          </span>
          <span className="text-black mt-4">
            {data?.issue_stocks} {data?.unit_name}
          </span>
        </div>
        {batchStatus === 'returned' && (
          <div className="d-flex flex-column px-24 border-start border-1 border-grey-200">
            <span className="text-grey-500 text-label-extra-small fw-bold">
              RETURN QTY&nbsp;
            </span>
            <span className="text-black mt-4">
              {data?.return_stocks} {data?.unit_name}
            </span>
          </div>
        )}
        {batchStatus === 'utilized' && (
          <div className="d-flex flex-column px-24 border-start border-1 border-grey-200">
            <span className="text-grey-500 text-label-extra-small fw-bold">
              USED QTY&nbsp;
            </span>
            <span className="text-black mt-4">
              {data?.used_stocks} {data?.unit_name}
            </span>
          </div>
        )}
      </div>
      {batchStatus === 'issued' ? (
        <>
          {showUtilizeBtn && (
            <div className="d-flex w-100 gap-8">
              <Button
                block
                type="outlined"
                antdButtonProps={{
                  className: 'mt-16',
                  onClick: () => {
                    setShowUtilizeBtn(false)
                  },
                  disabled: updateUtilizeMaterial.isLoading,
                }}
              >
                Cancel
              </Button>
              <Button
                block
                antdButtonProps={{
                  className: 'mt-16',
                  onClick: () =>
                    updateUtilizeMaterial.mutate({ batch: data.id }),
                  loading: updateUtilizeMaterial.isLoading,
                }}
              >
                Mark as Utilized
              </Button>
            </div>
          )}
          {showReturnForm && (
            <div className="d-flex flex-column w-100 gap-8 mt-16">
              <div className="d-flex gap-12 justify-content-between align-items-center w-100">
                <YoloDate
                  title="Return Date"
                  name="return_date"
                  antdDatePickerProps={{
                    format: 'MMMM D, YYYY',
                  }}
                  required
                  andtFormItemProps={{
                    validateTrigger: 'onChange',
                    className: 'mt-12 w-50',
                  }}
                  showErrorMessage={false}
                />
                <FormInput
                  required
                  name={'return_stocks'}
                  title={`Return Qty (${data?.unit_name})`}
                  inputType="decimal"
                  showErrorMessage={false}
                  placeHolder="Enter Return Quantity"
                  rules={[
                    {
                      validator: (_, val) => {
                        if (val > data.issue_stocks || val === 0) {
                          message.error(
                            'Return Qty Should Not Greater Than Issue Qty'
                          )
                          return Promise.reject()
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                />
              </div>
              <div className="d-flex w-100 gap-8">
                <Button
                  block
                  type="outlined"
                  antdButtonProps={{
                    onClick: () => setShowReturnForm(false),
                    disabled: saveReturnMaterial.isLoading,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  block
                  antdButtonProps={{
                    onClick: () => form.submit(),
                    loading: saveReturnMaterial.isLoading,
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  )

  return (
    <FormWrapper
      antdFormProps={{
        form: form,
        onFinish: handleFinish,
      }}
    >
      {batchStatus === 'issued' ? (
        Content
      ) : (
        <Badge.Ribbon
          className={cn('text-label-extra-small fw-bold py-4 hippie_badge', {
            'bg-washed-purple': batchStatus === 'utilized',
            'bg-washed-green': batchStatus === 'returned',
          })}
          text={batchStatus === 'returned' ? 'RETURNED' : 'UTILIZIED'}
        >
          {Content}
        </Badge.Ribbon>
      )}
    </FormWrapper>
  )
}

export default Item
