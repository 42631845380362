import Image from '@components/Image'
import {
  useGetMaterialBatch,
  useGetMaterialDetailsById,
  useGetMaterialsArray,
  useGetRecipientName,
  useGetWorkOrderList,
  usePostMaterialIssue,
} from '@services/material-issue'
import { Col, Form, Select, Skeleton, Alert } from 'antd'
import { FC, useEffect, useState } from 'react'
import useDebounce from '@constants/index'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  Button,
  FormSelect,
  FormWrapper,
  SearchInput,
  Checkbox,
  FormInputDate as YoloDate,
} from 'yolo-design/components'
import { Color } from 'yolo-design/constants'
import { Cross, Sync } from 'yolo-design/icons'
import dayjs from 'dayjs'

interface Props {
  setDrawerOpen: (value: boolean) => void
  name: string
  listRefetch: () => void
}

const MaterialIssueDrawer: FC<Props> = ({ setDrawerOpen, listRefetch }) => {
  const closeDrawer = () => {
    setDrawerOpen(false)
    setMaterialsSearch('')
    setSearchRecipientName('')
    setStocksBatch('')
    setSelectedMaterial(false)
    searchParams.delete('materialId')
    setSearchParams(searchParams)
  }

  const [form] = Form.useForm()
  const [selectedMaterial, setSelectedMaterial] = useState<boolean>(false)
  const [selectedMaterialsError, setSelectedMaterialsError] =
    useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const searchIdForMaterial = searchParams.get('materialId')
  const { orgId = '' } = useParams()
  const [materialsSearch, setMaterialsSearch] = useState('')
  const [searchStockBatch, setStocksBatch] = useState('')
  const [searchRecipientName, setSearchRecipientName] = useState<string>('')

  const [selectedMaterialBatches, setSelectedMaterialBatches] = useState<
    number[]
  >([])

  const [sendingStockIssues, setSendingBatchIssues] = useState<
    { batch: string; issue_stocks: string }[]
  >([])

  const searchDebounced = useDebounce(materialsSearch, 500)
  const searchStockBatchDebounce = useDebounce(searchStockBatch, 500)
  const searchRecipientNameDebounce = useDebounce(searchRecipientName, 500)

  const materialsArray = useGetMaterialsArray(
    {},
    { search: searchDebounced, page_size: 10, orgId: orgId }
  )

  const { data: materialDetail } = useGetMaterialDetailsById(
    { enabled: !!Number(searchIdForMaterial) },
    { id: Number(searchIdForMaterial) }
  )

  const {
    list: materialBatchData,
    isLoading: isLoadingForBatchMaterials,
    lastItemRef,
  } = useGetMaterialBatch(
    { enabled: !!searchIdForMaterial },
    {
      id: searchIdForMaterial || '',
      search: searchStockBatchDebounce,
      page_size: 10,
      orgId: orgId,
    }
  )

  const tempVar = materialDetail?.raw_material_id?.length || 0

  const { data: workOrderList } = useGetWorkOrderList(
    { enabled: tempVar > 0 },
    { id: materialDetail?.raw_material_id }
  )

  const { data: recipientNameList } = useGetRecipientName(
    { enabled: !!Number(searchIdForMaterial) },
    { name: searchRecipientNameDebounce }
  )

  const postMaterialIssue = usePostMaterialIssue({
    onSuccess: () => {
      listRefetch()
      closeDrawer()
    },
  })

  // Checkbox onClick handler
  const handleSelectedMaterials = (id: number, e: any, stock: number) => {
    const isExistMaterial = selectedMaterialBatches?.findIndex(
      (el) => Number(el) === Number(id)
    )

    if (isExistMaterial !== -1) {
      const removeMaterial = selectedMaterialBatches?.filter(
        (el) => Number(el) !== Number(id)
      )
      setSelectedMaterialBatches(removeMaterial)
    } else {
      const removeNone = selectedMaterialBatches?.filter(
        (el) => Number(el) !== Number(0)
      )
      setSelectedMaterialBatches([...removeNone, id])
    }

    if (
      e &&
      !(
        sendingStockIssues.filter((value) => value.batch === String(id))
          .length > 0
      )
    ) {
      setSelectedMaterialsError(false)
      setSendingBatchIssues((prevState) => [
        ...prevState,
        { batch: String(id), issue_stocks: String(stock) },
      ])
    } else {
      setSendingBatchIssues((prevState) =>
        prevState.filter((value) => value.batch !== String(id))
      )
    }
  }

  useEffect(() => {
    form.setFieldValue('issue_date', dayjs())
  }, [])

  return (
    <>
      <FormWrapper
        antdFormProps={{
          form: form,
          onFinish: (e: { recipient: string; work_order: string[] }) => {
            if (selectedMaterialBatches.length > 0) {
              setSelectedMaterialsError(false)
              const data = {
                product: searchIdForMaterial || '',
                ...e,
                batch: sendingStockIssues,
              }
              postMaterialIssue.mutate(data)
            } else {
              setSelectedMaterialsError(true)
            }
          },
        }}
      >
        {/* Header */}
        <div
          className="d-flex justify-content-between align-items-center w-100 border-bottom border-1 border-grey-200 bg-white"
          style={{
            position: 'sticky',
            padding: '20px',
            overflowY: 'scroll',
            height: '53px',
            width: '100%',
            top: 0,
            zIndex: 10,
          }}
        >
          <div>
            <Button
              label="Close"
              size="medium"
              type="ghost"
              color={Color?.background.dark}
              iconPosition="left"
              icon={Cross}
              backgroundColor="white"
              antdButtonProps={{
                onClick: () => {
                  closeDrawer()
                },
                disabled: postMaterialIssue.isLoading,
              }}
            />
          </div>

          <Button
            label="Issue Inventory"
            type="solid"
            size="large"
            backgroundColor="blue"
            antdButtonProps={{
              onClick: () => form.submit(),
              loading: postMaterialIssue.isLoading,
            }}
          />
        </div>
        {selectedMaterial ? (
          <>
            <div className="p-24">
              <div className="d-flex gap-8">
                <Image
                  className="border-1 border border-grey-300 rounded-8 p-12"
                  height={120}
                  width={92}
                />
                <div className="d-flex flex-column  ms-12 justify-content-around">
                  <span className="text-heading-small fw-semibold">
                    {materialDetail?.name ? materialDetail?.name : 'Nil'}
                  </span>
                  <div
                    className="d-flex justify-content-start align-items-center gap-2 w-max-content"
                    onClick={() => {
                      setSelectedMaterial(false)
                      searchParams.delete('materialId')
                      setSearchParams(searchParams)
                    }}
                  >
                    <span className="text-title-small text-grey-700 cursor-pointer">
                      Change Item
                    </span>
                    <Sync size="medium" weight="thin" cursor={'pointer'} />
                  </div>
                </div>
              </div>
              <div className="d-flex w-100  mt-24 text-center">
                <Col
                  span={12}
                  className="d-flex flex-column border-end border-grey-200"
                >
                  <span className="text-body-large">
                    {materialDetail?.available_stock
                      ? materialDetail?.available_stock
                      : 'Nil'}
                  </span>
                  <span className="text-label-small fw-semibold text-grey-600 text-uppercase mt-8">
                    Available QTY
                  </span>
                </Col>
                {/* <Col
                  span={8}
                  className="d-flex flex-column border-start border-end border-grey-200"
                >
                  <span className="text-body-large ">
                    {materialDetail?.accounting_stock
                      ? materialDetail?.accounting_stock
                      : 'Nil'}
                  </span>
                  <span className="text-label-small fw-semibold text-grey-600 text-uppercase mt-8">
                    IN USE QTY
                  </span>
                </Col> */}
                <Col span={12} className="d-flex flex-column">
                  <span className="text-body-large">
                    {materialDetail?.allocated_stock
                      ? materialDetail?.allocated_stock
                      : 'Nil'}
                  </span>
                  <span className="text-label-small fw-semibold text-grey-600 text-uppercase mt-8">
                    Allocated QTY
                  </span>
                </Col>
              </div>
            </div>
            <div className="ps-24 pe-24 pt-24 border-top border-1 border-grey-200">
              <YoloDate
                title="Issue Date"
                name="issue_date"
                antdDatePickerProps={{
                  format: 'MMMM D, YYYY',
                  allowClear: false,
                }}
                required
                andtFormItemProps={{
                  validateTrigger: 'onChange',
                  className: 'mt-12',
                }}
              />
              <FormSelect
                name="recipient"
                antdSelectProps={{
                  showSearch: true,
                  onSearch: (e) => {
                    setSearchRecipientName(e)
                  },
                }}
                required
                optionsSelect={recipientNameList?.map((item) => {
                  return { label: item.name, value: item.id, name: item.name }
                })}
                title="Recipient Name"
              />
              <FormSelect
                title="Work Order"
                antdSelectProps={{
                  mode: 'multiple',
                  showSearch: false,
                  style: {},
                  allowClear: true,
                }}
                name="work_order"
                // required={workOrderList?.length != 0}
                optionsSelect={workOrderList?.map((item) => {
                  return { label: item.work_order_number, value: item.id }
                })}
                disabled={workOrderList?.length == 0}
              />
              {/* Here */}
              <div
                style={{
                  height: '80vh',
                  overflow: 'scroll',
                  backgroundColor: 'white',
                }}
              >
                <div
                  className=" w-100 border-bottom border-1 border-grey-200 bg-white"
                  style={{
                    position: 'sticky',
                    // padding: '20px',
                    overflowY: 'scroll',
                    height: '100px',
                    top: '0%',
                    width: '100%',
                    // top: 0,
                    zIndex: 9,
                  }}
                >
                  {selectedMaterialsError && (
                    <Alert
                      banner
                      description="Please select &nbsp;&nbsp;STOCK IN HAND &nbsp;&nbsp; materials!"
                      type="error"
                      className="py-12"
                    />
                  )}
                  <span className="text-label-extra-small text-grey-700 fw-semibold">
                    STOCK IN HAND
                  </span>
                  <SearchInput
                    name="search"
                    title="STOCK IN HAND"
                    placeholder="Search"
                    className="mt-16"
                    onChange={(e) => {
                      setStocksBatch(e.target.value)
                    }}
                    allowClear
                  />
                </div>

                <div className="mt-24 overflow-scroll">
                  {isLoadingForBatchMaterials ? (
                    <Skeleton active />
                  ) : (
                    materialBatchData?.map((item, index) => {
                      return (
                        <div
                          className="d-flex justify-content-between align-items-center"
                          key={index}
                          ref={
                            materialBatchData.length - 1 === index
                              ? lastItemRef
                              : null
                          }
                          style={{
                            borderBottom: `${
                              index % 2 == 0
                                ? `1px solid ${Color?.gray_shades?.gray_200}`
                                : 'none'
                            }`,
                          }}
                        >
                          <div className="d-flex flex-column w-100 pt-16 pb-16">
                            <span className="text-body-large fw-semibold mb-8">
                              {item.id ? item.barcode : 'Nil'}
                            </span>
                            <div className="d-flex text-body-medium fw-medium">
                              <div className="d-flex gap-2 pe-12 border-end border-1 border-grey-200">
                                <span className="text-grey-700">QTY:</span>
                                <span className="text-black">
                                  {item.stock || 0}
                                </span>
                              </div>
                              <div className="d-flex gap-2 text-center px-12 border-end border-1 border-grey-200">
                                <span className="text-grey-700">EPR:</span>
                                <span className="text-black">
                                  {item.epr ? item.epr : '-'}
                                </span>
                              </div>
                              <div className="d-flex gap-2 ps-12">
                                <span className="text-grey-700">Location:</span>
                                <span className="text-black">
                                  {item.location ? item.location : '-'}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Checkbox
                            antdCheckboxProps={{
                              name: item.id,
                            }}
                            isChecked={selectedMaterialBatches.includes(
                              Number(item.id)
                            )}
                            handleCheckboxChange={(e) => {
                              handleSelectedMaterials(
                                Number(item.id),
                                e,
                                Number(item.stock)
                              )
                            }}
                          />
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          // Select a material
          <div className="p-24">
            <Select
              className="w-100"
              style={{
                height: 40,
              }}
              filterOption={false}
              placeholder="Select a material"
              showSearch
              options={materialsArray.list?.map((item) => {
                return {
                  label: (
                    <div className="d-flex flex-column justify-content-between align-items-start">
                      <span className="text-text-label-large fw-medium">
                        {item.name}
                      </span>
                      <span className="text-label-extra-small">
                        {item.sku_code}
                      </span>
                    </div>
                  ),
                  value: item.id,
                }
              })}
              onSearch={(e) => {
                setMaterialsSearch(e)
              }}
              onSelect={(e) => {
                searchParams.set('materialId', e)
                setSearchParams(searchParams)
                setSelectedMaterial(true)
                setMaterialsSearch('')
              }}
              loading={materialsArray.isLoading}
            />
          </div>
        )}
      </FormWrapper>
    </>
  )
}

export default MaterialIssueDrawer
