import { createQueryKeys } from '@lukemorales/query-key-factory'

export const reportQueries = createQueryKeys('report', {
  stock: (p: {
    material_type?: string
    form?: string
    size?: string
    micron: string
  }) => [p],
  formType: (code: number) => [code],
  form: ['report', 'form'],
})
