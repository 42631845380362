import { createQueryKeys } from '@lukemorales/query-key-factory'

export const materialQueries = createQueryKeys('materials', {
  listWithId: (search) => [search],
  listWithMaterials: (search, page_size, orgId) => [search, page_size, orgId],
  listWithBatchMaterials: (search, page_size, orgId, id) => [
    search,
    page_size,
    orgId,
    id,
  ],
  listWithRecipientName: (search) => [search],
  getMaterialId: (id) => [id],
  getMaterialDetailsId: (id) => [id],
  getWorkOrderListId: (id) => [id],
  getIssuedMaterialId: (id) => [id],
  getAllMaterialData: ['materials', 'getAllMaterialData'],
  getAllIssuedMaterialsData: (q: {
    page_size: number
    status?: string
    search?: string
  }) => [q.page_size, q.status, q.search],
  getAllReturnMaterial: (q: {
    page_size: number
    status?: string
    search?: string
  }) => [q.page_size, q.status, q.search],
  getAllIssuedItemBatch: (id: number, search: string) => [id, search],
  getReportIssueMaterial: (q: {
    orgId: string
    product?: string
    from?: string
    to?: string
  }) => [q.orgId, q.product, q.from, q.to],
  getReportReturnMaterial: (q: {
    orgId: string
    product?: string
    from?: string
    to?: string
  }) => [q.orgId, q.product, q.from, q.to],
})
