import { ENV } from '@config/app.config'
import { IItems } from '@contracts/models/items'
import { DELETE, GET, POST, PUT } from '@lib/AxiosClient'

export const getItems = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page_size?: number
    page?: number
  }
): Promise<IItems[]> =>
  GET({
    url: `${ENV.INVOICE_API_BASE_URL}item/product-list/`,
    params,
    signal,
  })

export const getPolyItems = (
  signal: AbortSignal | undefined,
  params: {
    search?: string
    page_size?: number
    page?: number
    job_id: string
  }
): Promise<IItems[]> =>
  GET({
    url: `item/poly-list/`,
    params,
    signal,
  })
export const postItemTracking = (data: Partial<any>): Promise<any> =>
  POST({
    url: `${ENV.INVOICE_API_BASE_URL}bills/item-track/`,
    data: data?.data,
  })

export const getItemTracking = (data: Partial<any>): Promise<any> => {
  return POST({
    url: `${ENV.INVOICE_API_BASE_URL}bills/tracking-item/?unique_id=${data?.data}/`,
  })
}

export const putItemTracking = (data: Partial<any>): Promise<any> =>
  PUT({
    url: `${ENV.INVOICE_API_BASE_URL}bills/tracking-item-update/`,
    data: data?.data,
  })

export const AutomaticbatchCreate = (data: Partial<any>): Promise<any> =>
  POST({
    url: `${ENV.INVOICE_API_BASE_URL}bills/tracking-item-onboard/?unique_id=${data?.batchCreateData?.unique_id}/`,
    data: data?.batchCreateData?.data,
  })

export const deleteItemTrack = ({
  id,
  unit,
}: {
  id: number
  unit: number
}): Promise<any> => {
  return DELETE({
    url: `${ENV.INVOICE_API_BASE_URL}bills/item-track/${id}/${unit}/`,
  })
}
export const deleteEachItemTrack = ({ id }: { id: number }): Promise<any> =>
  DELETE({
    url: `${ENV.INVOICE_API_BASE_URL}bills/tracking-item/${id}/`,
  })

export const deleteItemTrackOnClose = ({ id }: { id: number }): Promise<any> =>
  DELETE({
    url: `${ENV.INVOICE_API_BASE_URL}bills/unsaved-batch-remove/${id}/`,
  })

export const batchValidateEachItemTrackingNumber = (
  data: Partial<any>
): Promise<any> =>
  POST({
    url: `${ENV.INVOICE_API_BASE_URL}bills/batch-id-unique-validate/`,
    data: data?.queryData,
  })
export const serialValidateEachItemTrackingNumber = (
  data: Partial<any>
): Promise<any> => {
  return POST({
    url: `${ENV.INVOICE_API_BASE_URL}bills/serial-id-unique-validate/`,
    data: data?.data,
  })
}
