import { queries } from '@constants/queryKeys'
import { UseMutationType, UseQueryDataType } from '@contracts/query'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as API from './api'

export const useItemListInfo: UseQueryDataType<
  any,
  { search?: string; page_size?: number }
> = (options, { search, page_size }) =>
  useQuery({
    queryKey: queries.items.list(search, page_size).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getItems(signal, {
        search,
        page: pageParam,
        page_size,
      })
    },
    ...options,
  })
export const usePolyListInfo: UseQueryDataType<
  any,
  { search?: string; page_size?: number; job_id: any }
> = (options, { search, page_size, job_id }) =>
  useQuery({
    queryKey: queries.items.poly(search, page_size, job_id).queryKey,
    queryFn: ({ signal, pageParam }) => {
      return API.getPolyItems(signal, {
        search,
        page: pageParam,
        page_size,
        job_id,
      })
    },
    ...options,
  })
export const useCreateItemTracking: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.postItemTracking, options)

export const useRetreiveItemTracking: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.getItemTracking, options)

export const usePutItemTracking: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.putItemTracking, options)

export const useRecreateItemTracking: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.AutomaticbatchCreate, options)

export const useDeleteItem: UseMutationType<any, any> = (options) =>
  useMutation(API.deleteItemTrack, options)
export const useDeleteItemTracking: UseMutationType<
  any,
  Partial<any> & { id: number }
> = (options) => useMutation(API.deleteEachItemTrack, options)

export const useBatchValidation: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.batchValidateEachItemTrackingNumber, options)

export const useSerialValidation: UseMutationType<any, Partial<any>> = (
  options
) => useMutation(API.serialValidateEachItemTrackingNumber, options)
export const useDeleteItemOnClose: UseMutationType<
  any,
  Partial<any> & { id: number }
> = (options) => useMutation(API.deleteItemTrackOnClose, options)
