import {
  Button,
  SearchInput,
  Table,
  Drawer,
  Tab,
  SwitchTab,
} from 'yolo-design/components'
import { Add, DoubleChevronRight } from 'yolo-design/icons'
import { useEffect, useState } from 'react'
import MaterialEditDrawer from './MaterialEditDrawer'
import MaterialIssueDrawer from './MaterialIssueDrawer'
import { useGetMaterialIssuesTable } from '@services/material-return'
import Tag from '@components/Tag'
import { useLocation, useSearchParams } from 'react-router-dom'
import { materialIssueStatus } from '@constants/index'
import useRouteNavigate from '@hooks/useRouteNavigate'
import { Spin } from 'antd'

const MaterialIssue = () => {
  const [issueDrawerOpen, setIssueDrawerOpen] = useState(false)
  const [editDrawerOpen, setEditDrawerOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const routeNavigate = useRouteNavigate()
  const { pathname } = useLocation()
  const searchValue = searchParams.get('search') || ''

  const activeTabId = searchParams.get('tab') || materialIssueStatus[0].key

  const { list, isLoading, isFetching, lastItemRef, refetch } =
    useGetMaterialIssuesTable(
      {},
      {
        page_size: 10,
        status: activeTabId === 'all' ? undefined : activeTabId,
        search: searchValue,
      }
    )

  useEffect(() => {
    const view = searchParams.get('view')
    if (view === 'true') {
      setEditDrawerOpen(true)
    } else {
      searchParams.delete('id')
      searchParams.delete('view')
      setSearchParams(searchParams)
      setEditDrawerOpen(false)
    }
  }, [searchParams])

  const handleSearch = (text: string) => {
    searchParams.set('search', text)
    setSearchParams(searchParams)

    if (text.length <= 0) {
      searchParams.delete('searchMI')
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    searchParams.delete('materialId')
    setSearchParams(searchParams)
  }, [])

  const defaultColumns = [
    {
      title: 'Issue No.',
      dataIndex: 'issue_number',
      width: '15%',
      render: (text: string, column: any, key: number) => (
        <div
          ref={list?.length || []?.length - 1 === key ? lastItemRef : null}
          className={`d-flex w-100 justify-content-between view-form-parent`}
        >
          <p>{text}</p>
          <Button
            type="ghost"
            antdButtonProps={{
              className: `view-form-button`,
              onClick: () => {
                searchParams.set('view', 'true')
                searchParams.set('id', column.id)
                setSearchParams(searchParams)
              },
            }}
            label="View"
            icon={DoubleChevronRight}
            iconPosition="right"
          />
        </div>
      ),
    },
    {
      title: 'Material',
      dataIndex: 'product_name',
      render: (val: any, column: any) => (
        <div className="d-flex flex-column">
          <span>{val}</span>
          <span className="text-label-extra-small mt-4 text-grey-600">
            {column.product_sku_code}
          </span>
        </div>
      ),
    },
    {
      title: 'Allocated To',
      dataIndex: 'recipient',
    },
    {
      title: 'Work Order',
      dataIndex: 'work_order',
      width: '25%',
      render: (rec: string[], column: any) => (
        <div className="d-flex gap-2" key={1}>
          {/* Tags will come here */}
          {rec ? (
            rec.map((item: string) => (
              <Tag name={item} key={column.issue_number} />
            ))
          ) : (
            <div className="w-100 d-flex justify-content-center">-</div>
          )}
        </div>
      ),
    },
    {
      title: 'Issue Date',
      dataIndex: 'issue_date',
      width: '11%',
      render: (text: string) => {
        const dateType = new Date(text).toLocaleDateString('en-US', {
          dateStyle: 'short',
        })
        return (
          <p className="d-flex justify-content-start">
            {dateType ? dateType : '-'}
          </p>
        )
      },
    },
    {
      title: 'Return Date',
      dataIndex: 'return_date',
      width: '11%',
      render: (text: string) => {
        const dateType = new Date(text).toLocaleDateString('en-US', {
          dateStyle: 'short',
        })
        return (
          <p
            className={`d-flex ${
              text ? `justify-content-start` : `justify-content-center`
            }`}
          >
            {text ? dateType : '-'}
          </p>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'issue_status_name',
      width: '10%',
      render: (text: string, column: any) => (
        <div>
          {/* Tags will come here */}
          {text ? (
            <p id={column.issue_status}>{text ? text : '-'}</p>
          ) : (
            <p className="d-flex justify-content-center">-</p>
          )}
        </div>
      ),
    },
  ]

  const handleTabChange = (key: string) => {
    searchParams.set('tab', key)
    setSearchParams(searchParams)
  }

  return (
    <>
      <div className="w-100 p-24">
        <div className="d-flex justify-content-between w-100 ">
          <div style={{ width: '540px' }}>
            <SwitchTab
              tabItems={[
                { key: 'material-issue', label: 'Issue' },
                { key: 'material-return', label: 'Return' },
              ]}
              selectedKey={pathname.split('/')[2]}
              onClick={(key) => {
                routeNavigate(key, { addOrg: true })
              }}
            />
          </div>
          <div className="d-flex ">
            <SearchInput
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
              className="me-16"
              placeholder="Search"
            />
            <Button
              label="Issue Material"
              antdButtonProps={{
                onClick: () => {
                  setIssueDrawerOpen(true)
                },
              }}
              size="large"
              icon={Add}
            />
          </div>
        </div>
        <Tab
          className="w-100 mt-12"
          items={materialIssueStatus}
          activeKey={activeTabId}
          onChange={handleTabChange}
        />
        {/* Issue material drawer */}
        <Drawer open={issueDrawerOpen} destroyOnClose customHeader closable>
          <MaterialIssueDrawer
            setDrawerOpen={setIssueDrawerOpen}
            key={2}
            name="material-edit-drawer"
            listRefetch={refetch}
          />
        </Drawer>

        {/* Material Edit Drawer */}
        <Drawer open={editDrawerOpen} customHeader closable>
          <MaterialEditDrawer
            setDrawerOpen={setEditDrawerOpen}
            key={2}
            name="material-issue-drawer"
            listRefetch={refetch}
          />
        </Drawer>
        <div className="d-flex position-relative w-100">
          <Table
            className="w-100"
            columns={defaultColumns}
            dataSource={list || []}
            rowKey={'id'}
            loading={isLoading}
          />
          {isFetching && (
            <div className="d-flex w-100 pb-20 position-absolute bottom-0 start-0 justify-content-center">
              <Spin />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default MaterialIssue
